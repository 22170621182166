<template>
  <!-- Parents -->
  <div class="px-7 py-8">
    <div class="heading">For Parents</div>
    <v-data-table
      fixed-header
      hide-default-footer
      :headers="headers"
      :items="itemsParent"
      :loading="loading"
      class="elevation-8"
      loading-text="Loading... Please wait"
      mobile-breakpoint="0"
    >
      <template v-slot:item="props">
        <tr>
          <td class="py-2">
            <div class="tableItemHead">{{ props.item.email_title }}</div>
            <div class="tableItemSubhead">
              {{ props.item.email_description }}
            </div>
          </td>
          <td class="text-center">
            <v-chip
              class="actionColumn-btn"
              @click="route(props.item.id)"
              v-if="props.item.user_type == 'admin'"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="25"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-chip>
          </td>
        </tr>
      </template>
    </v-data-table>

    <!-- Parents end -->
    <!-- Teachers -->
    <div class="heading">For Teachers</div>
    <v-data-table
      fixed-header
      hide-default-footer
      :headers="headers"
      :items="itemsTeacher"
      :loading="loading"
      class="elevation-8"
      loading-text="Loading... Please wait"
      mobile-breakpoint="0"
    >
      <template v-slot:item="props">
        <tr>
          <td class="py-2">
            <div class="tableItemHead">{{ props.item.email_title }}</div>
            <div class="tableItemSubhead">
              {{ props.item.email_description }}
            </div>
          </td>
          <td class="text-center">
            <v-chip
              class="actionColumn-btn"
              @click="route(props.item.id)"
              v-if="props.item.user_type == 'admin'"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="25"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-chip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <!-- Teachers ends -->
    <!-- Schools -->
    <div class="heading">For Schools</div>
    <v-data-table
      fixed-header
      hide-default-footer
      :headers="headers"
      :items="itemsSchool"
      :loading="loading"
      class="elevation-8"
      loading-text="Loading... Please wait"
      mobile-breakpoint="0"
    >
      <template v-slot:item="props">
        <tr>
          <td class="py-2">
            <div class="tableItemHead">{{ props.item.email_title }}</div>
            <div class="tableItemSubhead">
              {{ props.item.email_description }}
            </div>
          </td>
          <td class="text-center">
            <v-chip
              class="actionColumn-btn"
              @click="route(props.item.id)"
              v-if="props.item.user_type == 'admin'"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="25"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-chip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <!-- Schools ends -->
    <!-- Districts -->
    <div class="heading">For Districts</div>
    <v-data-table
      fixed-header
      hide-default-footer
      :headers="headers"
      :items="itemsDistrict"
      :loading="loading"
      class="elevation-8"
      loading-text="Loading... Please wait"
      mobile-breakpoint="0"
    >
      <template v-slot:item="props">
        <tr>
          <td class="py-2">
            <div class="tableItemHead">{{ props.item.email_title }}</div>
            <div class="tableItemSubhead">
              {{ props.item.email_description }}
            </div>
          </td>
          <td class="text-center">
            <v-chip
              class="actionColumn-btn"
              @click="route(props.item.id)"
              v-if="props.item.user_type == 'admin'"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="25"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-chip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <!-- Districts ends -->
    <!-- Donors -->
    <div class="heading">For Donors</div>
    <v-data-table
      fixed-header
      hide-default-footer
      :headers="headers"
      :items="itemsDonor"
      :loading="loading"
      class="elevation-8"
      loading-text="Loading... Please wait"
      mobile-breakpoint="0"
    >
      <template v-slot:item="props">
        <tr>
          <td class="py-2">
            <div class="tableItemHead">{{ props.item.email_title }}</div>
            <div class="tableItemSubhead">
              {{ props.item.email_description }}
            </div>
          </td>
          <td class="text-center">
            <v-chip
              class="actionColumn-btn"
              @click="route(props.item.id)"
              v-if="props.item.user_type == 'admin'"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="25"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-chip>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import Axios from "@/api/BaseAxios";
import { API_EMAIL_AUTOMATED_LIST } from "@/constants/APIUrls";
import { ROUTER_URL } from "@/constants/urls";
export default {
  name: "AutomatedEmailTemplate",
  data() {
    return {
      itemsParent: [],
      itemsTeacher: [],
      itemsSchool: [],
      itemsDistrict: [],
      itemsDonor: [],
      loading: false,
      headers: [
        {
          sortable: false,
          text: "Email Title & Description",
          align: "left",
          class: ["tableHeader-text", "tableHeader-bg", "pl-6"],
        },
        {
          width: "20%",
          sortable: false,
          text: "Action",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    route(id) {
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.automatedEmailForm.name,
        query: { id: id },
      });
    },
    getAutomatedEmail() {
      this.loading = true;
      const successHandler = (res) => {
        console.log(res.data, "automated");
        this.itemsParent = res.data.email_template_list.Parent;
        this.itemsTeacher = res.data.email_template_list.teacher;
        this.itemsSchool = res.data.email_template_list.school;
        this.itemsDistrict = res.data.email_template_list.district;
        this.itemsDonor = res.data.email_template_list.Donor;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["email_template_type"] = "automated";
      Axios.request_GET(
        API_EMAIL_AUTOMATED_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted() {
    this.getAutomatedEmail();
  },
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.heading {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 315%;
  /* or 88px */
  display: flex;
  align-items: center;
  color: #000000;
}
span.v-chip {
  border-radius: 4px;
}
.tableItemHead {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  /* identical to box height */
  color: #000000;
}
.tableItemSubhead {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #757575;
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
span.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
  height: 45px;
  width: 38px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
